import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Box, Text, Title } from '../../components/Core'
import clickIcon from '../../assets/image/svg/tabler_click.svg'
import redeemIcon from '../../assets/image/svg/ic_round-redeem.svg'
import cashIcon from '../../assets/image/svg/mdi_cash-usd-outline.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const ReferralsCard = ({ icon, title, children, ...rest }) => {
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  return (
    <Box className="mt-4 mt-md-0">
      <img width="45" height="45" src={icon} alt="" mb={2} />
      <Text color={userDarkMode ? 'light' : 'dark'} lineHeight={1.75}>
        {children}
      </Text>
    </Box>
  )
}

const Referrals = () => {
  const { t } = useTranslation()
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : userSettings.dark_mode
  return (
    <>
      {/* <!-- Feature section --> */}

      <Box
        border="1px solid"
        borderColor="border"
        borderRadius={10}
        py="25px"
        px="30px"
        bg={userDarkMode ? 'dark' : 'light'}
        mb={4}
      >
        <Title color={userDarkMode ? 'light' : 'dark'}>
          {t('partnershipsPage.referralP')}
        </Title>
        <Row>
          <Col md="4">
            <ReferralsCard icon={clickIcon}>
              {t('partnershipsPage.share')}
            </ReferralsCard>
          </Col>
          <Col md="4">
            <ReferralsCard icon={cashIcon}>
              {t('partnershipsPage.get$')}
            </ReferralsCard>
          </Col>
          <Col md="4">
            <ReferralsCard icon={redeemIcon}>
              {t('partnershipsPage.redeem')}
            </ReferralsCard>
          </Col>
        </Row>
      </Box>
    </>
  )
}

export default Referrals
