import React from 'react'
import MetaData from '../../components/MetaData'
import PageWrapper from '../../components/PageWrapper'
import Hero from '../../sections/common/Hero'
import { Container, Row, Col } from 'react-bootstrap'
import ReferralsSection from '../../sections/partnerships/referrals'
import GetStarted from '../../sections/landing1/GetStarted'
import { Section, Box, Text, Title } from '../../components/Core'
import * as styles from '../../sections/landing1/Home.module.css'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import NewTabIcon from '../../assets/image/svg/ic_newtab16.inline.svg'

const Referrals = () => {
  const { t } = useTranslation()
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return (
    <>
      <MetaData page="default" />
      <PageWrapper headerDark footerDark>
        <Hero title={t('referralsPage.heroTitle')}></Hero>
        <Section
          pt={[3, 4, 4, 5]}
          pb={[0, null, null, 0]}
          bg={userDarkMode ? 'black' : 'bg'}
          className="position-relative"
        >
          <Container>
            <Row className="justify-content-between">
              <Col lg="8" className="order-lg-2">
                <ReferralsSection />
              </Col>
              <Col lg="4" className="order-lg-2">
                <Box
                  border="1px solid"
                  borderColor="border"
                  borderRadius={10}
                  pt="25px"
                  px="30px"
                  pb="30px"
                  bg={userDarkMode ? 'dark' : 'light'}
                  mb={4}
                >
                  <Title color={userDarkMode ? 'light' : 'black'}>
                    {t('partnershipsPage.affiliateProg')}
                  </Title>
                  <Text color={userDarkMode ? 'light' : 'dark'}>
                    {t('partnershipsPage.interestedIn')}
                  </Text>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://coinigy.tapfiliate.com/"
                    className={`mt-4 ${styles.aTagButton}`}
                  >
                    {t('partnershipsPage.applyTapfiliate')}
                    <NewTabIcon
                      style={{
                        marginLeft: '5px',
                        width: '16px',
                        height: '16px',
                      }}
                      aria-label={t('newTab')}
                      role="img"
                    />
                  </a>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <GetStarted />
      </PageWrapper>
    </>
  )
}
export default Referrals
